exports.components = {
  "component---src-components-tags-js": () => import("./../../../src/components/tags.js" /* webpackChunkName: "component---src-components-tags-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-tags-js": () => import("./../../../src/pages/blog/tags.js" /* webpackChunkName: "component---src-pages-blog-tags-js" */),
  "component---src-pages-exchange-index-jsx": () => import("./../../../src/pages/exchange/index.jsx" /* webpackChunkName: "component---src-pages-exchange-index-jsx" */),
  "component---src-pages-exchange-submit-exchange-jsx": () => import("./../../../src/pages/exchange/submit-exchange.jsx" /* webpackChunkName: "component---src-pages-exchange-submit-exchange-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-markdown-remark-frontmatter-slug-url-jsx": () => import("./../../../src/pages/{markdownRemark.frontmatter__slugUrl}.jsx" /* webpackChunkName: "component---src-pages-markdown-remark-frontmatter-slug-url-jsx" */)
}

